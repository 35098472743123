import axios from 'axios'

import store from '@/store'
import {
    getToken
} from '../util/auth'
import Vue from 'vue'
Vue.prototype.$axios = axios
// create an axios instance
const service = axios.create({
    //原来的
    // baseURL: 'https://www.kytapp.com/api',
    baseURL: 'https://xxzs.kytapp.com/api',
    timeout: 5000, // request timeout
    // changeOrigin:true
})
 

// request interceptor
service.interceptors.request.use(
    config => {
         config.headers['token'] = getToken()
        config.headers['device'] = 'pc'
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(

    response => {
        const res = response.data

        return res
    },
    error => {
        e.reject(error)
    }
)

export default service